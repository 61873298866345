<template>
	<div class="content">
		<div class="news-banner">
			<img src="../assets/carType-banner.png" width="100%" />
			<!--<div class="news-banner-search flex-between pd22">-->
				<!--<input class="w-100 ft26" placeholder="请输入搜索关键词/配置编号" />-->
				<!--<van-icon name="search"></van-icon>-->
			<!--</div>-->
			<div class="banner-search">
				<div class="search-inner">
					<div class="news-banner-search flex-between pd22">
						<input class="w-100 ft26" v-model="keywordVal" @input.stop="getSearchList"
							   placeholder="请输入搜索关键词/配置编号" />
						<van-icon name="search"></van-icon>
					</div>
					<div class="search-resault" v-if="isSearch">
						<div class="ft24 pd20 line1" v-for="(item,index) in selectOptions" :key="index" @click="handleSelectVal(item.id, item.name)">
							{{item.name}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ft28 pd30 van-hairline--bottom">
			<span>当前位置：</span>
			<span @click="$router.push('/')">首页 </span>>
			<span @click="$router.push('/carTypeIntro')">车型介绍 </span>>
			<span class="red" @click="$router.push('/carType-details')">车型详情 </span>
		</div>
		<div class="pd30">
			<div class="video-player">
				<video :poster="form.carInfo.mainPic" :src="form.carInfo.mainVideo" controls width="100%" height="100%" style="background-color: #000;"></video>
				<ul class="video-other">
					<!--<li @click="handleMoreVideo(form.carInfo.code)">-->
						<!--<img src="../assets/video-more-icon.png" style="width: .2rem;margin: 0 auto .1rem;" />-->
						<!--<div class="ft18 blf tc">更多视频</div>-->
					<!--</li>-->
					<li @click="handleFunVideo(form.carInfo.code)">
						<img src="../assets/video-gongneng-icon.png" style="width: .2rem;margin: 0 auto .1rem;" />
						<div class="ft18 blf tc">功能视频</div>
					</li>
				</ul>
			</div>
			<div class="ft28 l16 pt26 mb30">
				{{form.carInfo.name}}
			</div>
			<div class="pd30 details-bg">
				<div class="red ft34 bold" v-if="form.carInfo && form.carInfo.price > 0">¥{{form.carInfo.price}}万</div>
				<div class="red ft34 bold" v-else>暂无报价</div>
				<!--<div class="ft24 bl9 pt12">库存：{{form.carInfo.stock}}台</div>-->
				<!--<div class="ft24 bl9 pt12">颜色：{{form.carInfo.colorCode}}</div>-->
			</div>
			<div class="bl9 ft22 l18 pd24">
				车型：{{form.carInfo.code}}<br />
				车款：{{form.carInfo.carStyle}}<br />
				排量：{{form.carInfo.displacementCode}}<br />
				功率：{{form.carInfo.power}}
			</div>
			<div class="flex-between">
				<div class="pd24">
					<!--<div class="button line flex" @click="isCollection=!isCollection" :class="isCollection?'fill':''" >-->
						<!--<img src="../assets/collect-icon.png" style="width: 0.3rem;" />-->
						<!--<span class="ml20">收藏</span>-->
					<!--</div>-->
					<div class="button line flex">
						<img src="../assets/car-buy-icon.png" style="width: 0.3rem;" />
						<span class="ml20">在线购车</span>
					</div>
					<div class="button line flex" @click="handleJoinCar(form.carInfo.code)">
						<img src="../assets/duibi-icon2.png" style="width: 0.3rem;" />
						<span class="ml20">加入对比</span>
					</div>
				</div>
				<div class="tc ft26">
					<img src="../assets/qrcode-img.png" style="width: 2rem;" />
					<div>小程序查看</div>
				</div>
			</div>
			<div class="introinfo-nav tc ft26 van-hairline--bottom pb30 mt40 mb30">
				<van-row>
					<van-col :span="12">
						<div :class="current==0?'red':''" @click="changeNav(0)" class="van-hairline--right">详细介绍</div>
					</van-col>
					<van-col :span="12">
						<div :class="current==1?'red':''" @click="changeNav(1)">参数配置</div>
					</van-col>
				</van-row>
			</div>
			<div v-if="current==0" v-html="form.details.content">
				<!--<van-row :gutter="15">-->
					<!--<van-col :span="12" v-for="(item,index) in 2" :key="index">-->
						<!--<div class="video-list">-->
							<!--<div class="video-list-img">-->
								<!--<van-image width="100%" height="100%"></van-image>-->
							<!--</div>-->
						<!--</div>-->
					<!--</van-col>-->
				<!--</van-row>-->
				<!--<div class="pt20 pb20">-->
					<!--<div class="flex ft26 l16 pt30">-->
						<!--<img src="../assets/item-img.png" style="width: .18rem;" class="fls0" />-->
						<!--<span class="ml14">限量版实心丰田标后尾门限量标</span>-->
					<!--</div>-->
					<!--<div class="flex ft26 l16 pt30">-->
						<!--<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0" />-->
						<!--<span class="ml14">真皮座椅:(主副驾驶双电动座椅主10副8)</span>-->
					<!--</div>-->
					<!--<div class="flex ft26 l16 pt30">-->
						<!--<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>-->
						<!--<span class="ml14">8气囊前后包围</span>-->
					<!--</div>-->
					<!--<div class="flex ft26 l16 pt30">-->
						<!--<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>-->
						<!--<span class="ml14">真皮座椅:(主副驾驶双电动座椅主10副8)</span>-->
					<!--</div>-->
				<!--</div>-->
			</div>
			<div v-if="current==1">
				<div class="pb20">
					<div class="flex ft26 l16">
						<img src="../assets/item-img.png" style="width: .18rem;" class="fls0" />
						<span class="ml14">价格：{{form.carInfo.price}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0" />
						<span class="ml14">车款：{{form.carInfo.carStyle}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">品牌：{{form.carInfo.brandCode}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">车型：{{form.carInfo.code}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">产品尺寸：{{form.carBasicInfo.carSize}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">油箱容量：{{form.carBasicInfo.tankCapacity}} Ltrs</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">排量：{{form.carInfo.displacementCode}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">功率：{{form.carInfo.power}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">扭矩：{{form.carBasicInfo.torque}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">发动机形式：{{form.carBasicInfo.engineType}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">燃油类别：{{form.carBasicInfo.fuelCategory}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">变速器：{{form.carBasicInfo.transmission}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">四驱：{{form.carBasicInfo.fourWheel}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">差速：{{form.carBasicInfo.diffSpeed}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">轮毂及尺寸：{{form.carBasicInfo.tireSize}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">刹车系统：{{form.carBasicInfo.brakeSystem}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">悬挂系统：{{form.carBasicInfo.suspensionSystem}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">选择概叙：{{form.carAppearance.wheelSize}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">保险杠及包围：{{form.carAppearance.surround}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">泥挡：{{form.carAppearance.mudguard == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">玻璃：{{form.carAppearance.glass}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">天窗：{{form.carAppearance.skylight}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">刹车灯：{{form.carAppearance.stoplight == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">侧镜功能：{{form.carAppearance.sideMirror}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">门把手：{{form.carAppearance.doorHandle}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">脚踏：{{form.carAppearance.pedal}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">防撞杠：{{form.carAppearance.bumper == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">大灯形式：{{form.carAppearance.xenonHeadlamp}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" style="width: .18rem;"  class="fls0"/>
						<span class="ml14">转向灯：{{form.carAppearance.corneringLamp == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">前雾灯：{{form.carAppearance.frontFogLamp == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">备胎：{{form.carAppearance.spareTyre}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">汽车中网：{{form.carAppearance.carGrid}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">行李架：{{form.carAppearance.luggageRack == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">日间行车灯：{{form.carAppearance.runLights == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">头灯清洗：{{form.carAppearance.headlampCleaning == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">保护条：{{form.carAppearance.protectionStrip == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">彩条：{{form.carAppearance.colorBar == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">备胎形式：{{form.carAppearance.spareTyreLogo}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">拖钩：{{form.carAppearance.towHook}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">安全性概括：{{form.carSafety.safetyDesc}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">底盘升降：{{form.carAppearance.carTail == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">气囊前座/窗帘/前侧/司机膝盖：{{form.carSafety.gasBag}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">电眼：{{form.carSafety.electricEye}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">防碰撞安全系统：{{form.carSafety.antiCollision == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">防碰撞安全系统：{{form.carSafety.kdss == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">电调记忆方向盘：{{form.carVipassana.steeringWheel == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">中央手扶箱：{{form.carVipassana.jewelryBox == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">桃木装饰：{{form.carVipassana.decorate}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">真皮桃木方向盘+桃木档杆：{{form.carVipassana.corium}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">油箱盖开启器：{{form.carVipassana.opener == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">座椅材质+座位数：{{form.carVipassana.seatNum}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">前排电座：{{form.carVipassana.frontSeat}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">司机记忆座：{{form.carVipassana.memorySeat == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">司机座腰部承托/垂直调节：{{form.carVipassana.verticalAdjust == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">车内后视镜：{{form.carVipassana.rearviewMirror}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">遮阳板：{{form.carVipassana.sunVisor == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">化妆镜及照明灯：{{form.carVipassana.vanityMirror == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">组合仪表：{{form.carVipassana.ipc}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">前后雨刷：{{form.carEquipment.rearWiper == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">电窗：{{form.carEquipment.electricWindow}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">电吸门：{{form.carEquipment.electricDoor}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">电动侧滑门：{{form.carEquipment.electricAutoDoor}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">中央门锁：{{form.carEquipment.centralLock == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">无钥匙进入+一键式启动：{{form.carEquipment.touchStart == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">后挡风加热：{{form.carEquipment.weifaHotline == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">迎宾灯：{{form.carEquipment.welcomeLamp == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">收音机,AUX,USB,蓝牙,9喇叭：{{form.carEquipment.radioSetting}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">空调形式：{{form.carEquipment.automaticAir}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">雷测（自适应巡航）：{{form.carEquipment.cruiseControl == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">冰箱：{{form.carEquipment.iceBox == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">遥控启动：{{form.carEquipment.remoteStart == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">摄像头：{{form.carEquipment.camera}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">导航：{{form.carEquipment.navSite == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">倒影：{{form.carEquipment.inverted == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">后排娱乐系统：{{form.carEquipment.entertainment}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">低速巡航：{{form.carEquipment.speedCruise == 0 ? '无' : '有'}}</span>
					</div>
					<div class="flex ft26 l16 pt30">
						<img src="../assets/item-img.png" width="16" />
						<span class="ml14">特色配置：{{form.carEquipment.characteristic}}</span>
					</div>


				</div>
			</div>
			<div class="flex-between pt30 van-hairline--bottom pb30">
				<div class="ft30">推荐车型</div>
				<div class="flex ft24 bl9" @click="$router.push('/carTypeIntro')">
					<span>查看更多</span>
					<van-icon name="arrow"></van-icon>
				</div>
			</div>
		</div>
		<div class="pl30 pr30">
			<van-row :gutter="15">
				<van-col :span="12" v-for="(item,index) in recommendList" :key="index">
					<div class="video-list"  @click="handleCarDetails(item.id)">
						<div class="video-list-img">
							<div class="ft24 blf line1 tag blf">编号：{{item.code}}</div>
							<van-image width="100%" height="100%" :src="item.mainPic"></van-image>
						</div>
						<div class="l16 ft24 bl6 mt20 line2 mb30">{{item.name}}</div>
					</div>
				</van-col>
			</van-row>
		</div>
	</div>
</template>

<script>
	import { carDetails, pageCarList, saveVisitCar, updateDruation} from '@/api/index.js'
	export default {
		data() {
			return {
				visitId: '',
				keywordVal: '',
				isSearch: false,
				// 查询参数
				queryCarParams: {
					code: '',
					name: '',
					keyword:'', // 关键词
					category: 2,
					pageNum: 1,
					pageSize: 100
				},
				form: {
					carInfo:{
						id: '',
						code: '', // 车型编码
						name: '',  // 车型名称
						mainPic: '', // 车型主图
						mainVideo: '', // 车型主视频
						category:'', // 车型分类
						brandCode: '', // 品牌
						seriesCode: '', // 系列
						countryCode: '',  // 国别
						colorCode: '', // 颜色
						displacementCode: '',  // 排量
						carStyle: '', // 车款
						remark: '', // 备注
						stock: 0, // 库存
						power: '', // 功率
						price: '', // 价格
						vipPrice: '' // vip价格
					},
					carBasicInfo:{
						id: '',
						infoCode: '',  // 车型配置编码
						price: '', // 价格
						remark: '', // 备注
						keyword: '', // 搜索关键词
						carTail: 0, // 是否有尾翼
						carStyle: '', // 车款
						settingCode: '', // 配置编号
						brandCode: '', // 汽车品牌
						modelCode: '', // 车型编码
						carSize: '', // 产品尺寸
						tankCapacity: '', // 油箱容量
						displacement: '', // 排量
						power: '', // 功率
						torque: '', // 扭矩
						engineType: '', // 发动机形式
						fuelCategory: '', // 燃油类别
						transmission: '', // 变速器
						fourWheel: '', // 四驱
						diffSpeed: '', // 差速
						tireSize: '', // 轮胎尺寸
						brakeSystem: '', // 刹车系统:前/后
						suspensionSystem: '', // 悬挂系统:前/后
					},
					carAppearance:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						wheelSize: '', // 轮毂及尺寸
						surround: '',  // 保险杠及包围
						mudguard: '0', // 泥挡
						glass: '', // 玻璃
						skylight: '', // 天窗
						stoplight: '0', // 刹车灯
						sideMirror: '', // 侧镜
						doorHandle: '', // 门把手
						pedal: '', // 脚踏
						bumper: '0', // 防撞杠
						xenonHeadlamp: '', // 氙气大灯
						corneringLamp: '0', // 转向灯
						frontFogLamp: '0', // 前雾灯
						spareTyre: '', // 备胎
						carGrid: '', // 汽车中网
						luggageRack: '0', // 行李架
						runLights: '0', // 日间行车灯
						headlampCleaning: '0', // 头灯清洗
						protectionStrip: '0', // 保护条
						colorBar: '0', // 彩条
						spareTyreLogo: '', // 备胎标
						towHook: '',  // 拖钩

						carTail: '0', // 底盘升降
					},
					//******安全性******//
					carSafety:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						safetyDesc: '', // 安全性概括
						gasBag: '', // 气囊前座/窗帘/前侧/司机膝盖
						electricEye: '', // 电眼
						antiCollision: '0', // 防碰撞安全系统
						kdss: '0', // kdss
					},
					/**  内饰 ***/
					carVipassana:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						steeringWheel: '0', // 电调记忆方向盘
						jewelryBox: '0', // 中央首饰箱
						decorate: '', // 桃木装饰
						corium: '', // 真皮桃木方向盘+桃木档杆
						opener: '0', // 油箱盖开启器
						seatNum: '', // 座椅材质+座位数
						frontSeat: '', // 前排电座
						memorySeat: '0', // 司机记忆座(前排通风或加热)
						verticalAdjust:'0', // 司机座腰部承托/垂直调节
						rearviewMirror: '', // 车内后视镜(手/自动防炫目)
						sunVisor:'0', // 遮阳板
						vanityMirror: '0', // 化妆镜及照明灯
						ipc: '', // 组合仪表
					},
					/** 电子设备 **/
					carEquipment:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						rearWiper: '0', // 前后雨刷
						electricWindow: '', // 电窗
						electricDoor: '', //电吸门
						electricAutoDoor: '', // 电动侧滑门
						centralLock: '0', // 中央门锁
						touchStart: '0', // 无钥匙进入+一键式启动
						weifaHotline: '0', // 尾发热线
						welcomeLamp: '0', // 迎宾灯
						radioSetting: '', // 收音机,AUX,USB,蓝牙,9喇叭
						automaticAir: '', // 前后自动空调(无后暖气)
						cruiseControl: '0', // 巡航定速(雷达)
						iceBox: '0', // 冰箱
						remoteStart: '0', // 遥控启动
						camera: '', // 摄像头
						antiTheft: '0', // 防盗系统
						navSite: '0', // 导航
						inverted: '0', // 倒影
						entertainment: '', // 后排娱乐系统
						winch: '0', // 电动绞盘
						speedCruise: '0', // 低速巡航
						characteristic: '', // 特色配置
					},
					details:{},
				},
				current:0,
				selectOptions: [],
				recommendList: [],  // 推荐车型
				selectValue: '',
				isShowCode:false,
				isCollection:false
			}
		},
		created(){
			this.getCarDetails(this.$route.query.id)
			this.getRecommendList()
		},
		methods: {
			//导航切换
			changeNav(index){
				this.current=index
			},

			/***  获取搜索列表 *****/
			getSearchList(){
				this.isSearch = true
				this.queryCarParams.category = ''
				this.queryCarParams.keyword = this.keywordVal
				this.queryCarParams.pageSize = 100
				pageCarList(this.queryCarParams).then(res => {
					if (res.code === 200) {
						this.selectOptions = []
						this.selectOptions = res.data.list;
					}
				})
			},

			getCarDetails(id){
				carDetails(id).then(res => {
					if(res.code == 200){
						if(res.data.carInfo){
							this.form.carInfo = {
								id: res.data.carInfo.id,
								code: res.data.carInfo.code, // 车型编码
								name: res.data.carInfo.name,  // 车型名称
								mainPic: res.data.carInfo.mainPic, // 车型主图
								mainVideo: res.data.carInfo.mainVideo, // 车型主视频
								category:res.data.carInfo.category, // 车型分类
								brandCode: res.data.carInfo.brandCode, // 品牌
								seriesCode: res.data.carInfo.seriesCode, // 系列
								countryCode: res.data.carInfo.countryCode,  // 国别
								colorCode: res.data.carInfo.colorCode, // 颜色
								displacementCode: res.data.carInfo.displacementCode,  // 排量
								carStyle: res.data.carInfo.carStyle, // 车款
								remark: res.data.carInfo.remark, // 备注
								stock: res.data.carInfo.stock, // 库存
								power: res.data.carInfo.power, // 功率
								price: res.data.carInfo.price, // 价格
								vipPrice: res.data.carInfo.vipPrice // vip价格
							}
						}
						if(res.data.carBasicInfo){
							this.form.carBasicInfo = {
								id: res.data.carBasicInfo.id,
								infoCode: res.data.carBasicInfo.infoCode,  // 车型配置编码
								price: res.data.carBasicInfo.price, // 价格
								remark: res.data.carBasicInfo.remark, // 备注
								keyword: res.data.carBasicInfo.keyword, // 搜索关键词
								carTail: res.data.carBasicInfo.carTail, // 是否有尾翼
								carStyle: res.data.carBasicInfo.carStyle, // 车款
								settingCode: res.data.carBasicInfo.settingCode, // 配置编号
								brandCode: res.data.carBasicInfo.brandCode, // 汽车品牌
								modelCode: res.data.carBasicInfo.modelCode, // 车型编码
								carSize: res.data.carBasicInfo.carSize, // 产品尺寸
								tankCapacity: res.data.carBasicInfo.tankCapacity, // 油箱容量
								displacement: res.data.carBasicInfo.displacement, // 排量
								power: res.data.carBasicInfo.power, // 功率
								torque: res.data.carBasicInfo.torque, // 扭矩
								engineType: res.data.carBasicInfo.engineType, // 发动机形式
								fuelCategory: res.data.carBasicInfo.fuelCategory, // 燃油类别
								transmission: res.data.carBasicInfo.transmission, // 变速器
								fourWheel: res.data.carBasicInfo.fourWheel, // 四驱
								diffSpeed: res.data.carBasicInfo.diffSpeed, // 差速
								tireSize: res.data.carBasicInfo.tireSize, // 轮胎尺寸
								brakeSystem: res.data.carBasicInfo.brakeSystem, // 刹车系统:前/后
								suspensionSystem: res.data.carBasicInfo.suspensionSystem, // 悬挂系统:前/后
							}
						}
						if(res.data.carAppearance){
							this.form.carAppearance = res.data.carAppearance
						}
						if(res.data.carSafety){
							this.form.carSafety = res.data.carSafety
						}
						if(res.data.carVipassana){
							this.form.carVipassana = res.data.carVipassana
						}
						if(res.data.carEquipment){
							this.form.carEquipment = res.data.carEquipment
						}
						if(res.data.carDetails){
							this.form.details = {
								id: res.data.carDetails.id,
								content: res.data.carDetails.content.replace(/\<img/gi, '<img style="max-width:100%;height:auto"')
							}
						}
					}
				}).then(()=>{
					this.getVisitCarInfo(id)
				})
			},

			getVisitCarInfo(id){
				let data = {
					unionid: '',
					carCode: id,
					visitType: 3
				}
				saveVisitCar(data).then(res=>{
					if(res.code == 200){
						this.visitId =  res.data
					}
				})
			},

			handleCarDetails(id){
				this.$router.push({
					path: '/carType-details',
					query:{
						id: id
					}
				})
				this.getCarDetails(id)
			},

			/**  获取汽车推荐信息列表 **/
			getRecommendList(){
				let query = {
					code: '',
					name: '',
					category: 3,
					pageNum: 1,
					pageSize: 4
				}
				pageCarList(query).then(res => {
					if (res.code === 200) {
						this.recommendList = res.data.list;
					}
				})
			},
			/** 搜索赋值 **/
			handleSelectVal(id, name){
				this.keywordVal = name;
				this.isSearch = false;
				/** 跳转车型详情信息 **/
				this.$router.push({
					path: '/carType-details',
					query:{
						id: id
					}
				})
			},

			/** 跳转到更多视频 ***/
			handleMoreVideo(code){
				this.$router.push({
					path: '/carType-video-list',
					query:{
						code: code
					}
				})
			},
			/** 功能视频 **/
			handleFunVideo(code){
				this.$router.push({
					path: '/carType-GNvideo-list',
					query:{
						code: code
					}
				})
			},

			handleJoinCar(code){
				this.$router.push({
					path: '/carTypeVs',
					query:{
						code: code
					}
				})
			},

			updateVisitTime(){
				updateDruation(this.visitId).then(res=>{

				})
			}
		},

		destroyed() {
			this.updateVisitTime()
		}
	}
</script>

<style scoped="scoped">
	.news-banner {
		position: relative;
	}

	.news-banner .news-banner-search {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		background-color: rgba(255, 255, 255, 0.8);
		width: 4.58rem;
		font-size: .32rem;
	}

	input {
		background: transparent;
	}

	.video-list .video-list-img {
		width: 3.37rem;
		height: 3.36rem;
		background-color: #ffffff;
		border-radius: .1rem;
		overflow: hidden;
		position: relative;
	}

	.video-list-img .tag {
		background: rgba(0, 0, 0, .5);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		padding: .1rem .2rem;
		border-radius: 0.08rem 0rem 0rem 0rem;
	}

	.video-player {
		height: 5rem;
		position: relative;
	}
	.video-player .video-other{
		position: absolute;
		top: 50%;
		right: .3rem;
		transform: translateY(-50%);
	}
	.video-player .video-other li{
		width: 1rem;
		height: 1rem;
		text-align: center;
		margin-bottom: .4rem;
		background-color: rgba(0,0,0,.8);
		border-radius: 100px;
		box-sizing: border-box;
		padding-top: .2rem;
	}
	.video-player /deep/ .video-js {
		height: 100%;
	}

	.content /deep/ .vjs-custom-skin>.video-js .vjs-big-play-button {
		width: 1.03rem;
		height: 1.04rem !important;
		background-color: rgba(255, 255, 255, .8);
		border-radius: 0.51rem;
		opacity: 0.5;
		background-position: center;
		background-size: cover;
		display: flex;
		align-items: center;
		margin-left: -.56rem;
	}

	.content /deep/ .vjs-icon-placeholder:before {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.details-bg {
		background-color: #f5f5f5;
	}
	.button {
		border-radius: .1rem;
		border: solid 0.01rem #9e9e9e;
		padding: .15rem .2rem;
		margin-bottom: .15rem;
		color: #666;
	}
	.button.fill{
		background-color: #B5232E;
		color: #FFFFFF;
		border-color: #B4232F;
	}
</style>
